<template>
  <b-tabs class="page-tabs" pills>
    <b-tab v-if="isActiveUser">
      <template #title>
        <span>
          Badge & Feedback
        </span>
      </template>
      <div class="tab-pane__content">
        <loader-info v-if="isLoading" />
        <div v-if="!isLoading">
          <user-wait-message :read-only="personReadOnlyMode" v-if="!isActiveUser"/>
          <user-feedback
            v-if="hasScoreData && isActiveUser"
            :total-point="totalPoint"
            :count-total-wait="countTotalWait"
            :count-total-wait-max="countTotalWaitMax"
            :count-total="countTotal"
            :count-total-max="countTotalMax"
            :count-feedback-made-total="countFeedbackMadeTotal"
            :count-feedback-made-total-max="countFeedbackMadeTotalMax"
            :serial-code-creation="serialCodeCreation"
            :serial-code="serialCode"
          />
          <validation-action
            v-if="isWaitUser"
            :submit-validation="submitValidation"
            :is-not-complete-info="isNotCompleteInfo"
            :notValid="!userValidate"
          />
        </div>
      </div>
    </b-tab>
    <b-tab v-if="isActiveUser">
      <template #title>
        <span>
          Statistiche
        </span>
      </template>
      <div class="tab-pane__content">
        <loader-info v-if="isLoading" />
        <div v-if="!isLoading">
          <user-wait-message :read-only="personReadOnlyMode" v-if="!isActiveUser"/>
          <score-feedback
            v-if="hasScoreData && isActiveUser"
            :serialCodeCreation="serialCodeCreation"
          />
          <validation-action
            v-if="isWaitUser"
            :submit-validation="submitValidation"
            :is-not-complete-info="isNotCompleteInfo"
            :notValid="!userValidate"
          />
        </div>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import { extractErrorMessage, isNotEmpty } from '../../utils/validators';
import { formatDecimal } from '../../utils/formatterHelper';

const ValidationAction = () => import('@/components/helpers/ValidationAction.vue');
const ScoreFeedback = () => import('@/components/userSections/ScoreFeedback.vue');
const UserWaitMessage = () => import('@/components/userSections/UserWaitMessage.vue');
const UserFeedback = () => import('@/components/userSections/UserFeedback.vue');
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');

export default {
  name: 'TabStats',
  components: {
    ValidationAction,
    ScoreFeedback,
    UserWaitMessage,
    LoaderInfo,
    UserFeedback,
  },
  computed: {
    isActiveUser() {
      return this.$store.getters['subject/isActiveUser'];
    },
    isWaitUser() {
      const status = this.$store.getters['subject/activityPersonStatus'];
      return status.waitApproval;
    },
    isNotCompleteInfo() {
      if (this.isCompanyProfile) {
        return !this.$store.getters['subject/canSendCompanyValidation'];
      }
      return !this.$store.getters['subject/canSendPersonValidation'];
    },
    userValidate() {
      return !this.notValid && this.extendedStatus.userStatus === 'valid';
    },
    serialCodeCreation() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.serialCodeCreation;
      }
      return '';
    },
    isLoading() {
      if (!this.isCompanyProfile) {
        this.changePreloaderStatus(this.$store.getters['subject/isLoading'] || this.$store.getters['score/isLoading']);
      }
      return this.$store.getters['subject/isLoading'] || this.$store.getters['score/isLoading'];
    },
    personReadOnlyMode() {
      return !this.$store.getters['subject/canEditPerson'];
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    hasScoreData() {
      return this.$store.getters['score/hasCurrentScore'];
    },
    totalPoint() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return formatDecimal(score.totalPoint);
      }
      return 0;
    },
    countTotalWait() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.feedbackWait;
      }
      return 0;
    },
    countTotalWaitMax() {
      let increm = this.countTotalWait * 0.2;
      if (increm < 1) {
        increm = 2;
      }
      return this.countTotalWait + increm;
    },
    countTotal() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.globalVotes?.countTotal;
      }
      return 0;
    },
    countTotalMax() {
      let increm = this.countTotal * 0.2;
      if (increm < 1) {
        increm = 2;
      }
      return this.countTotal + increm;
    },
    countFeedbackMadeTotal() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.feedbackMade;
      }
      return 0;
    },
    countFeedbackMadeTotalMax() {
      let increm = this.countFeedbackMadeTotal * 0.2;
      if (increm < 1) {
        increm = 2;
      }
      return this.countFeedbackMadeTotal + increm;
    },
    serialCode() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.serialCode;
      }
      return '';
    },
  },
  methods: {
    setDashboardState() {
      this.$store.dispatch('setDashboard', 0);
    },
    submitValidation() {
      this.notValid = false;
      this.$confirm(
        {
          message: "Vuoi richiedere la validazione dell'anagrafica?",
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              const entityId = this.isCompanyProfile ? this.entityId : null;
              this.$store.dispatch('subject/sendValidationRequest', entityId).then(() => {
                this.submitted = false;
              },
              (error) => {
                if (error.status === 412) {
                  this.notValid = true;
                } else {
                  this.message = extractErrorMessage(error);
                }
                this.submitted = false;
              });
            }
          },
        },
      );
    },
    changePreloaderStatus(state) {
      // console.log('state', state);
      this.hasLoaded = state;
      console.debug('TabStats setpreloader', state);
      this.$store.dispatch('setPreloader', state);
    },
  },
};
</script>

<style scoped>

</style>
